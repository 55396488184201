<template>
  <div>
    <span v-show="!categories" class="font-weight-bold">Nenhuma categoria encontrada, verifique suas categorias ativas no menu correspondente</span>
    <v-autocomplete
      chips
      deletable-chips
      multiple
      solo
      auto-select-first
      v-model="categoriesSelected"
      :items="categories"
      item-text="name"
      item-value="id"
      outlined
      label="Categorias"
      v-if="categories"
      dense
      @change="v => $emit('selected', categoriesSelected)"
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @click="selectAllCategories">
          <v-list-item-action>
            <v-icon
              :color="categoriesSelected.length > 0 ? 'indigo darken-4' : ''"
            >
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Selecionar Todos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <v-divider class="mt-2"></v-divider>
    </v-autocomplete>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {CATEGORIES_GET} from "@/store/actions/categories.type";
import {OnlyIDArr} from "@/utils/arrays";

export default {
  name: "client-categories-select",
  async mounted() {
    await store.dispatch(CATEGORIES_GET);
  },
  data() {
    return {
      categoriesSelected: [],
    }
  },
  methods: {
    selectAllCategories() {
      this.$nextTick(() => {
        // console.log(this.categoriesSelected.length === this.categories.length);
        if (this.allEmployee) {
          this.categoriesSelected = [];
          this.$emit('selected', []);
        } else {
          this.categoriesSelected = OnlyIDArr(this.categories);
          this.$emit('selected', this.categoriesSelected);
        }
      });
    }
  },
  async beforeMount() {
    this.categoriesSelected = this.employee.categories ?? [];
  },
  computed: {
    ...mapGetters(["categories", "employee"]),
    icon() {
      if (this.allEmployee) return "mdi-close-box";
      if (this.algumasCategorias) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allEmployee() {
      return this.categoriesSelected.length === this.categories.length;
    },
    algumasCategorias() {
      return this.categoriesSelected.length > 0;
    }
  }
};
</script>
